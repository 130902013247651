function localStorageExpires() {
    var toRemove = [],                      //Itens para serem removidos
        currentDate = new Date().getTime(); //Data atual em milissegundos

    for (var i = 0, j = localStorage.length; i < j; i++) {
        var key = localStorage.key(i),
            itemValue = localStorage.getItem(key);

        //Verifica se o formato do item para evitar conflitar com outras aplicações
        if (itemValue && /^\{(.*?)\}$/.test(itemValue)) {

            //Decodifica de volta para JSON
            var current = JSON.parse(itemValue);

            //Checa a chave expires do item especifico se for mais antigo que a data atual ele salva no array
            if (current.expires && current.expires <= currentDate) {
                toRemove.push(key);
            }
        }
    }

    // Remove itens que já passaram do tempo
    // Se remover no primeiro loop isto poderia afetar a ordem,
    // pois quando se remove um item geralmente o objeto ou array são reordenados
    for (var i = toRemove.length - 1; i >= 0; i--) {
        localStorage.removeItem(toRemove[i]);
    }
}

localStorageExpires();//Auto executa a limpeza

/**
 * Função para adicionar itens no localStorage
 * @param {string} chave Chave que será usada para obter o valor posteriormente
 * @param {*} valor Quase qualquer tipo de valor pode ser adicionado, desde que não falhe no JSON.stringify
 * @param {days} dias Tempo de vida do item
 */
function setLocalStorage(chave, valor, days) {
    var expirarem = new Date().getTime() + (86400000 * days);

    localStorage.setItem(chave, JSON.stringify({
        "value": valor,
        "expires": expirarem
    }));
}

/**
 * Função para obter itens do localStorage que ainda não expiraram
 * @param {string} chave Chave para obter o valor associado
 * @return {*} Retorna qualquer valor, se o item tiver expirado irá retorna undefined
 */
function getLocalStorage(chave) {
    localStorageExpires();//Limpa itens

    var itemValue = localStorage.getItem(chave);

    if (itemValue && /^\{(.*?)\}$/.test(itemValue)) {

        //Decodifica de volta para JSON
        var current = JSON.parse(itemValue);

        return current.value;
    }
}

var politicaCookie = getLocalStorage('politica_cookie');
console.log(politicaCookie);
if (!politicaCookie) {
    $('.aviso_politica_privacidade').show();
}

$('.aceito-cookie').on('click', function(){
    /*$.ajax({
        url: '/actions.php?act=aceitoCookie',
        success: function (response) { //alert(response);
            if (response == '') {*/
                setLocalStorage('politica_cookie', true, 365);
                var politicaCookie = getLocalStorage('politica_cookie');
                console.log(politicaCookie);
                $('.aviso_politica_privacidade').hide();
           /* }
        }
    });*/
});

$(function(){
    Inputmask.extendAliases({
        'celular': {
            mask: "(99) 99999-9999"
        },
        'cpf':{
            mask: "999.999.999-99"
        },
        'cnpj':{
            mask: "99.999.999/9999-99"
        },
        'cpfcnpj':{
            mask: ['999.999.999-99', '99.999.999/9999-99']
        }
    });
    
    $('.celular').inputmask("celular");
    //$('.telefone').inputmask("telefone");
    //autoUnmask
    $(".cnpj").inputmask("cnpj");
    $(".cpf").inputmask("cpf");
    $(".cpfcnpj").inputmask('cpfcnpj');

    $('.moedaReal').inputmask('decimal', {
        radixPoint:",",
        groupSeparator: ".",
        autoGroup: true,
        digits: 2,
        digitsOptional: false,
        placeholder: '0',
        rightAlign: false,
        onBeforeMask: function (value, opts) {
          return value;
        }
    });


    // APLICA MASCARA PARA 8 E 9 DIGITOS COM DDD
    $('.telefone').inputmask({
        "mask": "(99) 9999-9999"
    })
    .on('keyup',function(e){
        var value = $(this).inputmask('unmaskedvalue');
        if(value.length == 10 && value.charAt(2) == 9){
            $(this).inputmask('remove');
            $(this).inputmask('(99) 99999-9999');
        }else if(value.length == 10 && value.charAt(2) != 9){
            $(this).inputmask('remove');
            $(this).inputmask('(99) 9999-9999');
        }
    });
});